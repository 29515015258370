<template>
  <div>
    <div class="row gutter-b">
      <div class="col-md-12">
        <div class="card card-custom">
          <div class="card-header card-header-fixed border-0 px-6">
            <div class="row align-items-center col-12 px-0">
              <div class="col-3 pr-0">
                <vs-select
                  v-if="diners"
                  v-model="dinerSearch"
                  :options="diners"
                  :placeholder="
                    $t('mealsystem.transactions.placeholder.search_diners')
                  "
                  label="last_name"
                  :reduce="(diners) => diners.diner_id"
                />
              </div>
              <div class="col-3 pr-0">
                <vs-select
                  v-model="typeSearch"
                  :options="types_options"
                  :placeholder="$t('mealsystem.transactions.placeholder.types')"
                  :clearable="false"
                />
              </div>
              <div class="col-3 pr-0">
                <div class="input-group input-group-lg input-group-solid">
                  <date-picker
                    v-model="dateSearch"
                    range
                    :placeholder="
                      $t('mealsystem.transactions.placeholder.date_search')
                    "
                    format="DD.MM.YYYY"
                    value-type="YYYY-MM-DD"
                  ></date-picker>
                </div>
              </div>

              <b-button
                variant="secondary"
                class="mr-3 ml-3 pr-2"
                @click.prevent="removeFilters"
              >
                <i class="fa fa-times icon-md"></i>
              </b-button>
              <b-button variant="primary" @click.prevent="searchTransactions">
                {{ $t("button.filter") }}
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--begin::Card-->
    <div class="card card-custom">
      <!--begin::Body-->
      <div class="card-body">
        <div class="table-responsive">
          <table
            class="table table-head-custom table-head-bg table-vertical-center"
          >
            <thead>
              <tr class="text-left text-uppercase">
                <th width="30">
                </th>
                <th width="180">
                  {{ $t("mealsystem.transactions.label.amount") }}
                </th>
                <th width="180">
                  {{ $t("mealsystem.transactions.label.kind_of_payment") }}
                </th>
                <th>{{ $t("mealsystem.transactions.label.diner_name") }}</th>
                <th width="150">
                  {{ $t("mealsystem.transactions.label.created_at") }}
                </th>
              </tr>
            </thead>
            <tbody v-if="!loading">
              <tr
                v-for="(transaction, index) in transactions.data"
                :key="index"
              >
                <td>
                  <span>
                      <span 
                        class="far fa-file-pdf mr-5 text-danger"
                        v-b-tooltip.left='$t("mealsystem.diners.title.download_document")'
                        @click.prevent="downloadDocument(transaction.transfer_id)"
                      ></span>
                    </span>
                </td>
                <td>
                  <span v-if="transaction.type == 'DEPOSIT'"
                    ><strong class="text-success"
                      >+ {{ transaction.amount }}</strong
                    ></span
                  >
                  <span v-if="transaction.type == 'WITHDRAW'"
                    ><strong class="text-danger"
                      >- {{ transaction.amount }}</strong
                    ></span
                  >
                </td>
                <td>
                  <span>{{ transaction.kind_of_payment }}</span>
                </td>
                <td>
                  <span>{{ transaction.user.full_name }}</span>
                </td>
                <td>
                  <span>{{
                    transaction.created_at | moment("D. M. YYYY HH:MM")
                  }}</span>
                </td>
              </tr>
            </tbody>
          </table>
          <Pagination :data="transactions.meta"></Pagination>
        </div>
      </div>
      <!--end::Body-->
    </div>
    <!--end::Card-->
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Pagination from "@/view/content/Pagination";
import ApiService from "@/core/services/api.service";

export default {
  data() {
    return {
      loading: false,
      page: 1,
      search: "",
      transactions: null,
      diners: null,
      dinerSearch: null,
      dateSearch: null,
      typeSearch: null,
      types_options: ["DEPOSIT", "WITHDRAW"],
    };
  },
  components: {
    Pagination,
  },
  beforeMount() {
    this.getTransactions();
    this.getDiners();
  },
  metaInfo() {
    return { title: this.$t("mealsystem.meta.diners_transactions") };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: this.$t("mealsystem.meta.diners"),
        subtitle: this.$t("mealsystem.meta.diners_transactions"),
      },
    ]);
  },
  computed: {
    perpage() {
      return this.$store.state.pagination.pagination.itemsPerPage;
    },
  },
  methods: {
    getTransactions() {
      this.loading = true;
      ApiService.setHeader();
      ApiService.apiGet(
        "/meal-system/diners/transfer-logs?page=" +
          this.page +
          "&perpage=" +
          this.perpage +
          "&diner_id=" +
          this.dinerSearch +
          "&type=" +
          this.typeSearch +
          "&date=" +
          this.dateSearch
      ).then((response) => {
        this.transactions = response.data;
        this.loading = false;
      });
    },
    onUpdatePage(newPage) {
      this.page = newPage;
      this.getTransactions();
    },
    searchTransactions() {
      this.page = 1;
      this.getTransactions();
    },
    removeFilters() {
      this.page = 1;
      this.dinerSearch = null;
      this.typeSearch = null;
      this.dateSearch = "";
      this.getTransactions();
    },
    getDiners() {
      this.loading = true;
      ApiService.setHeader();
      ApiService.apiGet(
        "/meal-system/diners?departments=null&categories=null"
      ).then((response) => {
        this.diners = response.data.data;
        this.loading = false;
      });
    },
    downloadDocument(transaction_id){
      alert(transaction_id);
    },
  },
};
</script>
